<template>
  <b-card no-body title="Moderation List">
    <div>
      <b-button-toolbar class="toolbar-top-margin justify-content-end">
        <b-button-group class="mr-2 mb-1" vertical>
          <b-form-checkbox id="checkbox-show-rejected" v-model="showRejected" name="checkbox-show-all" @input="showAllChanged"> Rejected Only </b-form-checkbox>
        </b-button-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-form-checkbox id="checkbox-show-all" v-model="showAll" name="checkbox-show-all" @input="showAllChanged"> Show all </b-form-checkbox>
        </b-button-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="refresh">
            <feather-icon icon="RefreshCwIcon" v-if="!getStoreLoading" />
            <b-spinner v-if="getStoreLoading" small style="margin-bottom: 3px" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getStoreLoading" rounded="sm" opacity="0.8">
      <b-table hover striped responsive="sm" :fields="fields" :items="imagesListToUse" :per-page="perPage" :current-page="currentPage">
        <template #cell(name)="data">
          <b-link @click="onFileClick(data)">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(listing_name)="data">
          {{ getForMediaType(data.item) }} <br />
          {{ getItemDescription(data.item) }}
        </template>
        <template #cell(facility)="data">
          {{ data.item.facilityName }} <br />
          {{ data.item.facilityLicense }}
        </template>
        <template #cell(status)="data">
          <span>
            Active:
            <template v-if="data.item.active">
              <feather-icon icon="CheckCircleIcon" size="24" class="text-success"></feather-icon>
            </template>
            <template v-else>
              <feather-icon icon="XCircleIcon" size="24" class="text-danger"></feather-icon>
            </template>
          </span>
          <br />
          <span>
            Moderated:
            <template v-if="data.item.moderated">
              <feather-icon icon="CheckCircleIcon" size="24" class="text-success"></feather-icon>
            </template>
            <template v-else>
              <feather-icon icon="XCircleIcon" size="24" class="text-danger"></feather-icon>
            </template>
          </span>
          <br />

          <span>
            Rejected:
            <template v-if="data.item.rejected">
              <feather-icon icon="CheckCircleIcon" size="24" class="text-success"></feather-icon>
            </template>
            <template v-else>
              <feather-icon icon="XCircleIcon" size="24" class="text-danger"></feather-icon>
            </template>
          </span>
        </template>

        <template #cell(rejected)="data">
          <template v-if="data.value">
            <feather-icon icon="CheckCircleIcon" size="24" class="text-success"></feather-icon>
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="24" class="text-danger"></feather-icon>
          </template>
        </template>
      </b-table>
    </b-overlay>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <div class="col-1">
        <b-form-select id="perPageSelect" :value="perPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
        <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ imagesListToUse.length }}</p>
      </div>
      <div>
        <b-pagination
          :value="currentPage"
          :total-rows="imagesListToUse.length"
          per-page="10"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          limit="1"
          hide-ellipsis
          hide-goto-end-buttons
          @change="onPageChanged"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { getImagesList, onLoadImages, setSelectedImage, getStoreLoading } from "@/views/moderate-images/useModerateImages";
import { onMounted, computed, ref, watch } from "@vue/composition-api";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";

export default {
  name: "ModerateImages",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup() {
    const { router } = useRouter();
    const fields = [
      {
        key: "name",
        label: "File Name",
        sortable: true
      },
      {
        key: "mediaType",
        label: "Media Type",
        sortable: true
      },
      {
        key: "listing_name",
        label: "Media for",
        sortable: true
      },
      {
        key: "facility",
        label: "Facility"
      },
      { key: "status", sortable: true },
      { key: "uploadUser", label: "Uploaded By", sortable: true, tdClass: "text-center" },
      { key: "uploadedAt", label: "Uploaded", sortable: true, tdClass: "text-center" }
    ];
    const showAll = ref(false);
    const showRejected = ref(false);
    const currentPage = ref(1);
    const perPage = ref(10);
    const pageOptions = DEFAULT_PAGE_OPTIONS;

    const getItemDescription = (row) => {
      switch (row.forMediaType) {
        case "L":
        case "S":
          return row.listingName;
        case "F":
          return row.facilityName + " (" + row.facilityLicense + ")";
        default:
          return row.forMediaType && row.forMediaType === "F" ? row.facilityLicense : row.listingName;
      }
    };

    const getForMediaType = (row) => {
      if (!row || !row.forMediaType) {
        return "Unknown";
      }

      switch (row.forMediaType) {
        case "L":
          return "Listing";
        case "F":
          return "Facility";
        case "S":
          return "Sample";
        default:
          return "Unknown: " + row.forMediaType;
      }
    };

    onMounted(() => {
      showAllChanged();
    });

    watch(showAll, (newValue, oldValue) => {
      if (showAll.value) {
        showRejected.value = false;
      }
      showAllChanged();
    });

    watch(showRejected, (newValue, oldValue) => {
      if (showRejected.value) {
        showAll.value = false;
      }
      showAllChanged();
    });

    const showAllChanged = async () => {
      await onLoadImages(showAll.value, showRejected.value);
    };

    const perPageChanged = async (val) => {
      perPage.value = val;
    };

    const refresh = async () => await onLoadImages(showAll.value, showRejected.value);

    const imagesListToUse = computed(() => {
      return !getImagesList.value ? [] : getImagesList.value;
    });

    const onFileClick = async (row) => {
      setSelectedImage(row.item);
      router.push({ name: "moderating-image" });
    };

    const onPageChanged = async (val) => {
      currentPage.value = val;
    };

    return {
      getImagesList,
      imagesListToUse,
      fields,
      getStoreLoading,
      onLoadImages,
      onFileClick,
      setSelectedImage,
      pageOptions,
      currentPage,
      onPageChanged,
      perPageChanged,
      perPage,
      showAll,
      showAllChanged,
      showRejected,
      refresh,
      getForMediaType,
      getItemDescription
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
</style>
