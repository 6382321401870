import { ref, computed } from "@vue/composition-api";
import { requestLoadImageDetails, requestLoadImages, requestMediaModeration } from "@/service/ModerationService";

const images = ref([]);
const selectedImage = ref(null);
const selectedImageDetails = ref(null);
const loading = ref(false);

export const getImagesList = computed(() => images.value);
export const getSelectedImage = computed(() => selectedImage.value);
export const getSelectedImageDetails = computed(() => selectedImageDetails.value);
export const getStoreLoading = computed(() => loading.value);
export const setSelectedImage = (val) => (selectedImage.value = val);
export const setSelectedImageDetails = (det) => (selectedImageDetails.value = det);

export const onSaveDownloadedFile = (response, fileName, mType) => {
  mType = !mType ? "application/pdf" : mType;
  const blob = new Blob([response], { type: mType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const onLoadImages = async (showAll, showRejected) => {
  loading.value = true;
  try {
    const { data } = await requestLoadImages(showAll, showRejected);
    images.value = data;
  } catch (error) {
    //handle error
    console.log("error", error);
    //handleError("Error loading.", error);
  } finally {
    loading.value = false;
  }
};
export const onLoadImageDetails = async () => {
  loading.value = true;
  try {
    const selectedImg = selectedImage.value;
    if (!selectedImg) {
      return;
    }
    const req = {
      objectKey: selectedImg.key,
      forMediaType: selectedImg.mediaType
    };
    const { data } = await requestLoadImageDetails(req);
    selectedImageDetails.value = data;
  } catch (error) {
    //handle error
    console.log("error", error);
    //handleError("Error loading.", error);
  } finally {
    loading.value = false;
  }
};
export const onMediaModeration = async (flag, reason, note) => {
  console.log("Reject reason/note", reason, note);
  loading.value = true;
  try {
    const selectedImg = selectedImage.value;
    if (!selectedImg) {
      return;
    }
    const req = {
      objectKey: selectedImg.key,
      forMediaType: selectedImg.mediaType,
      rejectReason: reason ? reason.value : null,
      moderationNote: note ? note.value : null,
      flag: flag
    };
    console.log("moderate with", req);
    const { data } = await requestMediaModeration(req);
    selectedImageDetails.value = data;
  } catch (error) {
    //handle error
    console.log("error", error);
    //handleError("Error loading.", error);
  } finally {
    loading.value = false;
  }
};
