import axiosIns from "@/libs/axios";

export function requestLoadImages(showAll, showRejected) {
  return axiosIns.post(`/admin/moderationQueue/`, { showAll: showAll, showRejected: showRejected });
}

export function requestLoadImageDetails(req) {
  return axiosIns.post(`/admin/moderationObject/`, req, {
    responseType: "blob"
  });
}

export function requestMediaModeration(req) {
  return axiosIns.post(`/admin/moderateObject/`, req);
}
